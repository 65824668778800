import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    redirect:"frequency",//设置重定向
    children:[
      {
        path:"frequency",
        name:"frequency",
        meta:{
          isShow:true,
          title:"运动频次"
        },
        component: () => import(/* webpackChunkName: "frequency" */ '../views/SportsFrequency.vue')
      },
      {
        path:"plan",
        name:"plan",
        meta:{
          isShow:true,
          title:"运动安排"
        },
        component: () => import(/* webpackChunkName: "plan" */ '../views/SportsPlan.vue')
      }
      ,
      {
        path:"action",
        name:"action",
        meta:{
          isShow:true,
          title:"动作库"
        },
        component: () => import(/* webpackChunkName: "action" */ '../views/ActionLibrary.vue')
      }
      ,
      {
        path:"indensity",
        name:"indensity",
        meta:{
          isShow:true,
          title:"运动强度"
        },
        component: () => import(/* webpackChunkName: "indensity" */ '../views/SportsIntensity.vue')
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
// router.beforeEach((to,from,next)=>{
//   const token: string | null =localStorage.getItem('token')
//   if(!token && to.path!=='/login'){
//     next('/login')
//   }else{
//     next()
//   }
// })
export default router
